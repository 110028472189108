import $ from "jquery";

function init() {
    $(".open-search").click(function (e) {
        e.preventDefault();
    
        $(this).addClass("d-none");
        $(".close-search").removeClass("d-none");
        $(".google-search-form > input").addClass("active");
        $(".google-search-form > input").focus();
    });
    
    $(".google-search-form > input").keyup(function (e) {
        if (e.originalEvent.key == "Enter") {
            $(".submit-search").click();
            return;
        } else if ($(this).val() != "") {
            if ($(".submit-search").hasClass("d-none")) {
                $(".close-search").addClass("d-none");
                $(".submit-search").removeClass("d-none");
            }
        } else {
            $(".close-search").removeClass("d-none");
            $(".submit-search").addClass("d-none");
        }
    });
    
    $(".close-search").click(function (e) {
        e.preventDefault();
    
        $(".google-search-form > input").removeClass("active");
        $(".google-search-form > button").addClass("d-none");
        $(".open-search").removeClass("d-none");
    });
    
    var myCallback = function () {
        if (document.readyState == "complete") {
            // Document is ready when CSE element is initialized.
            // Render an element with both search box and search results in div with id 'test'.
            google.search.cse.element.render({ div: "cse", tag: "search" });
        } else {
            // Document is not ready yet, when CSE element is initialized.
            google.setOnLoadCallback(function () {
                // Render an element with both search box and search results in div with id 'test'.
                google.search.cse.element.render({ div: "cse", tag: "search" });
            }, true);
        }
    };
    
    // Insert it before the CSE code snippet so that cse.js can take the script
    // parameters, like parsetags, callbacks.
    window.__gcse = {
        parsetags: "explicit",
        callback: myCallback,
    };
    
    (function () {
        var cx = "018304093702880060621:co6cw1dunvw";
        var gcse = document.createElement("script");
        gcse.type = "text/javascript";
        gcse.async = true;
        gcse.src = "https://cse.google.com/cse.js?cx=" + cx;
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(gcse, s);
    })();
}

export default init();