import { createApp } from "vue";

import TooltipComponent from "../../../vue/find-by-policy/TooltipComponent.vue";

const main = async () => {
    const app = createApp({
        components: {
            TooltipComponent,
        },
    });
    const root = app.mount("#app");
    return root;
};

export default main().then((root) => {
    console.log("Tooltip component loaded");
}).catch((err) => {
    console.error("Failed to load Tooltip component", err);
});
