import $ from "jquery";

function init() {
    $(document).on("ready", function () {
        $("#google_translate_element").hide();
    });
    $(".language-button").on("click", function (e) {
        $(this).toggleClass("active");

        if ($(this).hasClass("active")) {
            $(".goog-te-gadget-simple").trigger("click");
            let iframe = $(".goog-te-menu-frame");

            let body = iframe.contents().find("body"),
                widget = iframe.contents().find(".goog-te-menu2"),
                columns = body.contents().find("td");

            let top = $(".header-container").height();

            iframe.css({
                position: "absolute",
                width: "100%",
                left: "0",
                top: top + "px",
            });

            body.removeAttr("style");
            widget.removeAttr("style");
            widget.css({
                border: "none",
                background: "transparent",
                width: "100% !important",
                margin: "auto",
                overflow: "auto",
            });

            iframe
                .contents()
                .find("table")
                .css({ margin: "auto", background: "#000" });
            columns.find("div").css({ background: "#000" });
            columns.find("span").css({ color: "#fff" });
        }
    });
}

export default init();