import '../scss/site.scss';

import $ from "jquery";

import baseJS from "./components/base/index";

function MainJS() {
    return {
        init: $(document).on("ready", () => {
            baseJS();
        }),
    };
}

const mainJS = MainJS();
mainJS.init;

