import $ from "jquery";
import { Dropdown } from "bootstrap";
function init() {
    $(document).ready(function () {
        let url = new URL(window.location.href),
            path = window.location.pathname;
        if (path == "/contact" && url.searchParams.get("country")) {
            $(".country-contact-form select").val(url.searchParams.get("country"));
        }
    });

    $(".navbar-toggle").on("click", function () {
        $(this).toggleClass("is-open");
        $("nav.offcanvas").toggleClass("show");
    });

    $(document).on("ready", function () {
        $(".scroll-top").hide();
    });

    $(window).on("scroll", function () {
        var scrollTop = $(window).scrollTop();

        if (scrollTop > 180) {
            if ($(".scroll-top").is(":visible")) return;
            $(".scroll-top").fadeIn();
        } else {
            $(".scroll-top").fadeOut();
        }
    });

    $(".scroll-top, .scroll-top-btn").on("click", function () {
        $(window).scrollTop(0);
    });

    $(".nav-link").click(function (e) {
        if ($(this).hasClass("active")) return;
        $(".nav-link").toggleClass("active");
        $(".tab-content").toggleClass("d-none");
    });

    $(".decision-row").click(function () {
        window.location = $(this).data("href");
    });

    $(".sorter").click(function () {
        window.location.search = "column=" + $(this).data("column") + "&" + "order=" + $(this).data("order");
    });

    $("#input-dropdown").on("input-dropdown-selection", (e) => {
        const event = e.detail;

        if (event.name === "adminDropdown") {
            const country = event.data.country;
            const currentPolicy = event.data.currentPolicy;
            const countryPolicies = country.policies.map((policy) => policy.slug);

            let url = "/legislation/" + country.slug;

            if (currentPolicy.policy === "laws") {
                url += "/laws";
            } else if (countryPolicies.includes(currentPolicy.policy)) {
                url += "/" + currentPolicy.policy;
                if (currentPolicy.subPolicy && currentPolicy.subPolicy !== currentPolicy.policy) {
                    url += "/" + currentPolicy.subPolicy;
                }
            }

            window.location = url;
        }
    });
}

export default init();
